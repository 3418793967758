<template>
  <div class="image-import">
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept=".webp"
      multiple
      @change="handleFiles"
    >
    <div
      class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl"
      @click="$refs.fileInput.click()"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
    >
      <feather-icon
        class="dropIcon"
        icon="UploadCloudIcon"
        size="70"
      /><br>
      <span>Drop Image Files or </span>
      <span
        class="font-medium text-primary"
        @click.stop="$refs.fileInput.click()"
      >Browse</span>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageFiles: [],
    }
  },
  methods: {
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      const { files } = e.dataTransfer
      this.processFiles(files)
    },
    handleFiles(e) {
      const { files } = e.target
      this.processFiles(files)
    },
    processFiles(files) {
      const validFiles = Array.from(files).filter(this.isWebP)
      if (validFiles.length !== files.length) {
        this.$vs.notify({
          title: 'Upload Attempt',
          text: 'Only supports uploading .webp files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        })
      }
      this.imageFiles = validFiles
      if (this.onSuccess) this.onSuccess(this.imageFiles)
    },
    isWebP(file) {
      return /\.webp$/.test(file.name)
    },
  },
}
</script>
  
  <style lang="scss" scoped>
  .border-dashed {
    border-style: dashed !important;
  }
  .border-2 {
    border-width: 2px !important;
  }
  .py-16 {
     padding-top: 4rem !important;
     padding-bottom: 4rem !important;
   }
  .d-theme-border-grey-light {
    border-color: #dae1e7;
  }
  .d-theme-dark-bg, .d-theme-dark-light-bg {
    background-color: #fff;
  }
  .px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .text-xl {
    font-size: 1.25rem !important;
  }
  .block {
    display: block !important;
  }
  .relative {
    position: relative !important;
  }
  .dropIcon {
    opacity: 0.3;
  }
  </style>

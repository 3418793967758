<template>
  <b-card
    text-variant="center"
    class="card pt-4 pb-4"
  >
    <p class="mb-2">
      To upload images, ensure that the images are in <code>.webp</code> format and that the filenames include the school name followed by a suffix indicating the image type, such as <code>-logo</code>, <code>-primary</code>, or <code>-extra</code>.
      <br>
      To view full details about this module, <a
        href="https://docs.google.com/document/d/15vGnr9uy9TUD2LLuIrVC7GWWF0oMsKW4AQF9lgB8Mew/edit?usp=sharing"
        target="_blank"
      >click here</a>.
    </p>

    <ImportImages :onSuccess="handleSuccess" />

    <b-row
      v-if="imageFiles.length"
      class="mx-auto py-2"
      sm="8"
    >
      <b-col>
        <b-card>
          <table
            style="width:100%"
            class="border-collapse text-center"
          >
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light font-weight-bolder">
                Number Of Images :
              </td>
              <td class="p-2 border border-solid d-theme-border-grey-light">
                {{ imageFiles.length }}
              </td>
            </tr>
            <tr>
              <td class="p-2 border border-solid d-theme-border-grey-light font-weight-bolder">
                Number Of Schools :
              </td>
              <td class="p-2 border border-solid d-theme-border-grey-light">
                {{ uniqueSchoolNames.length }}
              </td>
            </tr>
          </table>
          <div class="text-center mt-2">
            <button
              class="btn btn-primary"
              @click="uploadFiles"
            >
              Upload Images
            </button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ImportImages from '@core/components/dropzone/ImportImages.vue'
import { BCard, BRow, BCol } from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    ImportImages,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      imageFiles: [],
      uniqueSchoolNames: [],
    }
  },
  methods: {
    handleSuccess(files) {
      this.imageFiles = files;
      this.uniqueSchoolNames = [...new Set(files.map(file => this.extractSchoolName(file.name)))];
    },
    async uploadFiles() {
      const formData = new FormData();
      this.imageFiles.forEach(file => {
        formData.append('files[]', file);
      });

      try {
        const response = await axios.post('https://school.itseducation.asia/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        console.log('Upload success:', response.data);
        this.notify({
          text: `All ${this.imageFiles.length} images have been uploaded successfully!`,
          variant: 'success',
        });

        // Clear imageFiles after successful upload
        this.imageFiles = [];
        this.uniqueSchoolNames = [];
      } catch (error) {
        console.error('Upload failed:', error);
        this.notify({
          text: 'Upload failed. Please try again.',
          variant: 'danger',
        });
      }
    },
    extractSchoolName(filename) {
      // Remove the file extension
      const filenameWithoutExt = filename.split('.').slice(0, -1).join('.');

      // Known suffixes to remove
      const suffixes = ['-logo', '-primary', '-extra'];

      // Remove any known suffix along with trailing numbers
      let schoolName = filenameWithoutExt;
      suffixes.forEach(suffix => {
        const regex = new RegExp(`(${suffix})(-\\d+)?$`);
        if (regex.test(schoolName)) {
          schoolName = schoolName.replace(regex, '').trim();
        }
      });

      return schoolName;
    },
  },
}
</script>

<style lang="scss" scoped>
.csc-btn {
  min-width: 130px !important;
  font-size: 12px;

  &:hover {
    background: #eaeaea !important;
  }
}
</style>
